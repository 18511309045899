import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import HomeNavigation from './HomeNavigation';
import HomeCreateLink from './HomeCreateLink';

import styles from './HomeHero.module.css';

export default function HomeHero () {
    return (
        <div className={`relative overflow-hidden gradient`}>
            <div className={` h-[150px] ${styles.whiteshadow} absolute bottom-0 w-[100%]`}></div>
            <HomeNavigation displayDisconnectButton className="mt-2"/>
            <div className='relative pt-6 pb-16 sm:pb-24'>
                <main className='mt-8 sm:mt-12'>
                    <div className='mx-auto max-w-7xl'>
                        <div className='w-full flex lg:flex-row flex-col px-4 lg:min-h-[470px]'>
                            <div className='w-full lg:w-2/3 sm:text-center md:mx-auto flex lg:text-left'>
                                <div>
                                    <h1 className='text-4xl text-center font-bold tracking-tight text-black-600 lg:text-start sm:text-5xl md:text-6xl'>
                                        The Simplest Way to Receive
                                        Crypto Payments
                                    </h1>
                                    <p className='mt-3 text-center text-gray-600 lg:text-start sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                                        One simple link to receive payments from 6+ chains and 1000+ tokens,<br/>
                                        converted and bridged to the token of your choice.
                                    </p>
                                    <div className='flex flex-row justify-center lg:justify-start'>
                                        <img src="/images/scheme4.png" className='w-[80%] mt-10'/>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-16 sm:mt-24 lg:mt-0 w-full lg:w-1/3'>
                                <HomeCreateLink />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

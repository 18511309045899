import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import HomeNavigation from "./HomeNavigation";
import HomeCreateLink from "./HomeCreateLink";
import { AiOutlineSafety } from "react-icons/ai";
import { BiNetworkChart } from "react-icons/bi";
import { BsFillSafeFill, BsCoin } from "react-icons/bs";
import { IoMdBusiness } from "react-icons/io";
import { HiOutlineStar } from "react-icons/hi";
import Safe from "../../public/Lottie/safe.json";
import Blockchain from "../../public/Lottie/Blockchain2.json"
import Business from "../../public/Lottie/Business2.json"
import Exact from "../../public/Lottie/ExactAmount2.json"
import Link from "../../public/Lottie/link1.json"
import Secure from "../../public/Lottie/Secure2.json"
import Lottie, { useLottie } from "lottie-react";
import Animation from "components/Animation";

// https://react-icons.github.io/react-icons/search?q=safe

interface IFeature {
    name: string;
    description: string;
    icon: JSX.Element;
    lottieFile?: any;
}
const features: IFeature[] = [
    {
        name: "Non custodial",
        description:
            "We don’t store your funds, all transfers go directly to and from your wallet",
        icon: <BsFillSafeFill color="#1f2937" size="60" className="" />,
        lottieFile: Safe
    },
    {
        name: "Decentralized",
        description:
            "A wallet is all you need to create your link, no need to sign up or complete KYC",
        // icon: (
        //     <>
        //         <BiNetworkChart color="#1f2937" size="60" />
        //     </>
        // )
        icon: <BiNetworkChart color="#1f2937" size="60" className="" />,
        lottieFile: Blockchain
    },
    {
        name: "Secure",
        description:
            "All swaps are conducted only through the best bridges & DEX’s in crypto using reputable aggregators, such as Li.Fi",
        // icon: (
        //     <>
        //         <AiOutlineSafety color="#1f2937" size="60" />
        //     </>
        // )
        icon: <AiOutlineSafety color="#1f2937" size="60" className="" />,
        lottieFile: Secure
    },
    {
        name: "Receive an exact amount",
        description:
            "Set a desired amount to receive in any token and the link will calculate the amount one needs to swap 2 you",
        icon: <BsCoin color="#1f2937" size="60" className=""/>,
        lottieFile: Exact
    },
    {
        name: "One link for all chains",
        description:
            "You can use the same link to receive payments from any of the supported chains",
        icon: <HiOutlineStar color="#1f2937" size="60" className=""/>,
        lottieFile: Link
    },
    {
        name: "Works for business",
        description:
            "Receive notifications and connect your online business to the blockchain with swap2.me API (coming soon)",
        icon: <IoMdBusiness color="#1f2937" size="60" className=""/>,
        lottieFile: Business
    }
];

export default function HomeFeatures() {
    return (
        <div className="bg-white py-12">
            <div className="mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">A better way to send money.</h2>
                <dl className="grid grid-cols-1 gap-8 lg:grid lg:grid-cols-3">
                    {features.map((feature, key) => (
                        <Feature feature={feature} key={key}/>
                    ))}
                </dl>
            </div>
        </div>
    );
}

const Feature = ({ feature }: { feature: IFeature }) => {
    const options = {
        animationData: feature.lottieFile,
        loop: true,
        autoplay: false
    };
    const { View, play, pause } = useLottie(options, {
        width: 80,
        height: 80
    });
    return (
        <div
            key={feature.name}
            // className={`rounded-xl bg-[url(/images/blur1.jpg)] p-6`}
            className={`rounded-xl bg-gradient-to-tr opacity-80 from-[#f6d6d3] to-[#c9c8f1]  p-6`}

            onMouseOver={() => {
                console.log("onMouseOver");
                play();
            }}
            onMouseLeave={() => {
                console.log("onMouseLeave");
                pause();
            }}
        >
            <dt className="flex flex-col items-center text-center">
                <div className="flex h-22 w-22 items-center justify-center rounded-full bg-white">
                    {View}
                </div>
                <p className="mt-6 text-2xl font-extrabold leading-8  text-[#32]">
                    {feature.name}
                </p>
            </dt>
            <dd className="mt-2 text-center font-medium leading-7 text-[#32]">
                {feature.description}
            </dd>
        </div>
    );
};

import { Combobox, Listbox, Transition } from '@headlessui/react';
import { useWeb3Modal } from '@web3modal/react';
import axios from 'axios';
import useAxios from 'axios-hooks';
import classNames from 'classnames';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { VscCopy } from 'react-icons/vsc';
import { toast, useLogin } from 'utils';
import { API_URL, CHAINS, COINS, IChain, ICoin, IWallet } from 'utils/constants';
import { useAccount, useConnect } from 'wagmi';


export default function HomeCreateLink () {
    const { address, isConnecting, isDisconnected, isConnected } = useAccount();
    const {login, signLoginMessage} = useLogin();
    const {
        connect,
        connectAsync,
        connectors,
        error: connectError,
        isLoading,
        pendingConnector
    } = useConnect({
        onSuccess: async data => {
            createAddress();
        }
    });

    const {
        isOpen: connectModalIsOpen,
        open: openConnectModal,
        close
    } = useWeb3Modal();

    const createAddress = async () => {

        const signResult = await signLoginMessage();
        if(!signResult){
            return;
        }
        const loginResult = await login(signResult);
        
        console.log("loginResult",loginResult)
        if (loginResult?.status == 'ok') {


            const createResult = await axios.post(`${API_URL}/payments`, {
                chain: selectedChain.id,
                coinAddress: selectedCoin.address,
                linkType: "user"
            },{
                withCredentials:true
            });

            if(createResult?.data?.status == "ok"){
                toast({
                    header:"Your link is created",
                    status:"success"
                })
                console.log("link created ",address);
                refetch();
            }else{
                toast({
                    header:createResult?.data?.error || "Failed to create link",
                    status:"error"
                })
            }
        }else{
            toast({
                header: loginResult?.data?.error,
                status:"error"
            })
        }
    }

    const [selectedChain, setSelectedChain] = useState<IChain>(CHAINS[0]);
    const [selectedCoin, setSelectedCoin] = useState<ICoin>(null);
    const [selectedProvider, setSelectedProvider] = useState<IWallet>(null);

    const [{ data: paymentsDataResponse, loading, error }, refetch] = useAxios(
        {
            method:"GET",
            url:`${API_URL}/payments/${address}`,
        },{
            manual: !address || !isConnected
        }
    );
    const [{ data: lifiTokensData }] = useAxios(
        'https://li.quest/v1/tokens',
        {}
    );

    console.log('paymentsDataResponse ', paymentsDataResponse);

    useEffect(() => {
        if (connectors) {
            // setSelectedProvider(connectors[0] as IWallet)
        }
    }, [connectors]);

    useEffect(() => {
        setSelectedCoin(null);
    }, [selectedChain]);

    const { lifiTokens } = useMemo(() => {
        if (selectedChain?.id && lifiTokensData?.tokens?.[1]?.length) {
            return {
                lifiTokens: lifiTokensData.tokens[selectedChain.id].filter(
                    x => {
                        return COINS.includes(x.symbol);
                    }
                )
            };
        }
        return {
            lifiTokens: []
        };
    }, [lifiTokensData, selectedChain]);

    const url = useMemo(() => {
        if (paymentsDataResponse?.paymentLink?.id) {
            return `https://swap2.me/${paymentsDataResponse?.paymentLink?.id}`;
        }
        return null;
    }, [paymentsDataResponse]);


    console.log("address ",address, paymentsDataResponse);

    if (address && paymentsDataResponse?.paymentLink?.id) {
        return (
            <div className='bg-white sm:mx-auto sm:w-full sm:max-w-md sm:rounded-lg'>
                <div className='px-4 py-8 sm:px-10'>
                    {/* <h2 className='font-bold text-xl'>You are connected</h2> */}
                    <div>
                        <label
                            htmlFor='account-number'
                            className='block text-sm font-medium text-gray-700'
                        >
                            Your link
                        </label>
                        <div className='relative mt-1 rounded-md shadow-sm'>
                            <input
                                type='text'
                                name='account-number'
                                id='account-number'
                                className='block w-full rounded-md border-gray-300 pr-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                value={url}
                            />
                            <div
                                className='cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3'
                                onClick={() => {
                                    navigator.clipboard.writeText(url);
                                    toast({
                                        header: 'Copied'
                                    });
                                }}
                                data-tooltip="Copy link"
                            >
                                <VscCopy />
                            </div>
                        </div>
                        <a
                            href={url}
                            rel='noreferrer'
                            target='_blank'
                            className='cursor-pointer mt-3 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        >
                            Open your payment link
                        </a>

                        <a
                            href={"/links"}
                            className='cursor-pointer mt-3 flex w-full justify-center rounded-md border border-transparent border-indigo-600 py-2 px-4 text-sm font-medium text-indigo-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        >
                            Manage your links
                        </a>
                    </div>
                    <a className='text-2xs'></a>
                </div>
            </div>
        );
    }

    return (
        <div className='bg-white sm:mx-auto sm:w-full sm:max-w-md sm:rounded-lg'>
            <div className='px-4 py-8 sm:px-10'>
                <div>
                    <h2 className='font-semibold text-xl'>
                        Create your link in 5 seconds
                    </h2>
                    <Listbox value={selectedChain} onChange={(c)=>{
                        setSelectedChain(c)
                        setSelectedCoin(null)
                    }}>
                        {({ open }) => (
                            <>
                                <Listbox.Label className='block text-sm font-medium text-gray-700 mt-1 mb-1'>
                                    1. Select chain
                                </Listbox.Label>
                                <div className='relative'>
                                    <Listbox.Button className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'>
                                        <span className='flex items-center'>
                                            <img
                                                src={selectedChain.img}
                                                alt=''
                                                className='h-6 w-6 flex-shrink-0 rounded-full'
                                            />
                                            <span className='ml-3 block truncate'>
                                                {selectedChain.name}
                                            </span>
                                        </span>
                                        <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
                                            <BsChevronDown />
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave='transition ease-in duration-100'
                                        leaveFrom='opacity-100'
                                        leaveTo='opacity-0'
                                    >
                                        <Listbox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                            {CHAINS.map(chainItem => (
                                                <Listbox.Option
                                                    key={chainItem.id}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active
                                                                ? 'text-white bg-indigo-600'
                                                                : 'text-gray-900',
                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={chainItem}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <div className='flex items-center'>
                                                                <img
                                                                    src={
                                                                        chainItem.img
                                                                    }
                                                                    alt=''
                                                                    className='h-6 w-6 flex-shrink-0 rounded-full'
                                                                />
                                                                <span
                                                                    className={classNames(
                                                                        selected
                                                                            ? 'font-semibold'
                                                                            : 'font-normal',
                                                                        'ml-3 block truncate'
                                                                    )}
                                                                >
                                                                    {
                                                                        chainItem.name
                                                                    }
                                                                </span>
                                                            </div>

                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active
                                                                            ? 'text-white'
                                                                            : 'text-indigo-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    {/* <CheckIcon
                                                                        className='h-5 w-5'
                                                                        aria-hidden='true'
                                                                    /> */}
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>

                    {selectedChain ? (
                        <Listbox
                            value={selectedCoin}
                            onChange={setSelectedCoin}
                        >
                            {({ open }) => (
                                <>
                                    <Listbox.Label className='block text-sm font-medium text-gray-700 mt-1 mb-1 z-100'>
                                        2. Select coin
                                    </Listbox.Label>
                                    <div className='relative'>
                                        <Listbox.Button className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'>
                                            <span className='flex items-center'>
                                                {selectedCoin ? (
                                                    <>
                                                        <img
                                                            src={
                                                                selectedCoin?.logoURI
                                                            }
                                                            alt=''
                                                            className='h-6 w-6 flex-shrink-0 rounded-full'
                                                        />
                                                        <span className='ml-3 block truncate'>
                                                            {selectedCoin?.name}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span>Select coin</span>
                                                )}
                                            </span>
                                            <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
                                                <BsChevronDown />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave='transition ease-in duration-100'
                                            leaveFrom='opacity-100'
                                            leaveTo='opacity-0'
                                        >
                                            <Listbox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                                {lifiTokens.map(coinItem => (
                                                    <Listbox.Option
                                                        key={`${coinItem.chainId}${coinItem.address}`}
                                                        className={({
                                                            active
                                                        }) =>
                                                            classNames(
                                                                active
                                                                    ? 'text-white bg-indigo-600'
                                                                    : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                            )
                                                        }
                                                        value={coinItem}
                                                    >
                                                        {({
                                                            selected,
                                                            active
                                                        }) => (
                                                            <>
                                                                <div className='flex items-center'>
                                                                    <img
                                                                        src={
                                                                            coinItem.logoURI
                                                                        }
                                                                        alt=''
                                                                        className='h-6 w-6 flex-shrink-0 rounded-full'
                                                                    />
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'font-semibold'
                                                                                : 'font-normal',
                                                                            'ml-3 block truncate'
                                                                        )}
                                                                    >
                                                                        {
                                                                            coinItem.name
                                                                        }
                                                                    </span>
                                                                </div>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active
                                                                                ? 'text-white'
                                                                                : 'text-indigo-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                        )}
                                                                    >
                                                                        {/* <CheckIcon
                                                                        className='h-5 w-5'
                                                                        aria-hidden='true'
                                                                    /> */}
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    ) : null}

                    {/* <>
                        <Listbox
                            value={selectedProvider}
                            onChange={setSelectedProvider}
                        >
                            {({ open }) => (
                                <>
                                    <Listbox.Label className='block text-sm font-medium text-gray-700 mt-1 mb-1 z-100'>
                                        3. Connect wallet
                                    </Listbox.Label>
                                    <div className='relative'>
                                        <Listbox.Button className='relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'>
                                            <span className='flex items-center'>
                                                {selectedProvider ? (
                                                    <>
                                                        <span className='ml-3 block truncate'>
                                                            {
                                                                selectedProvider?.name
                                                            }
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span>Select wallet</span>
                                                )}
                                            </span>
                                            <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
                                                <BsChevronDown />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave='transition ease-in duration-100'
                                            leaveFrom='opacity-100'
                                            leaveTo='opacity-0'
                                        >
                                            <Listbox.Options className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                                                {connectors.map(
                                                    connectorItem => (
                                                        <Listbox.Option
                                                            key={`${connectorItem.id}`}
                                                            className={({
                                                                active
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'text-white bg-indigo-600'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={
                                                                connectorItem
                                                            }
                                                        >
                                                            {({
                                                                selected,
                                                                active
                                                            }) => (
                                                                <>
                                                                    <div className='flex items-center'>
                                                                        <span
                                                                            className={classNames(
                                                                                selected
                                                                                    ? 'font-semibold'
                                                                                    : 'font-normal',
                                                                                'ml-3 block truncate'
                                                                            )}
                                                                        >
                                                                            {
                                                                                connectorItem.name
                                                                            }
                                                                        </span>
                                                                    </div>

                                                                    {selected ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-indigo-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    )
                                                )}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </> */}

                    <div className='mt-6'>
                        <button
                            disabled={!selectedCoin}
                            onClick={async () => {
                                console.log("click isConnected ",isConnected)
                                if(isConnected){
                                    createAddress()
                                }else{
                                    openConnectModal();
                                    // const connector = connectors.find(
                                    //     x => x.id === selectedProvider.id
                                    // )
                                    // const connectResult = await connectAsync({
                                    //     connector: connectors.find(
                                    //         x => x.id === selectedProvider.id
                                    //     )
                                    // });
                                }
                            }}
                            className={classNames([
                                'cursor-pointer flex w-full justify-center rounded-md py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none',
                                {
                                    'bg-gradient-to-r from-red-400 via-fuschia-500 to-indigo-500 hover:opacity-80':
                                        selectedChain && selectedCoin,
                                    'bg-indigo-300 opacity-80':
                                        !selectedChain || !selectedCoin
                                }
                            ])}
                        >
                            Connect wallet and create link
                        </button>
                    </div>
                </div>
            </div>
            <div className='border-t-2 border-gray-200 bg-gray-50 px-4 py-6 sm:px-10 rounded-b-lg'>
                <p className='text-xs leading-5 text-gray-500'>
                    By signing up, you agree to our{' '}
                    <a
                        href='/terms'
                        className='font-medium text-gray-900 hover:underline'
                    >
                        Terms and Conditions
                    </a>
                    .
                </p>
            </div>
        </div>
    );
}
